import Flickity from "flickity";

var elem = document.querySelector(".main-carousel");
var flkty = new Flickity(elem, {
  // options

  contain: false,
});

window.addEventListener("load", function () {
  var elem = document.querySelector(".menu-checkbox");
  var elem2 = document.querySelector(".sub-menu-dropdown-checkbox");
  elem.checked = false;
  elem2.checked = false;
});

jQuery(function ($) {
  $(".menu-trigger").on("click", function (e) {
    $("body").toggleClass("mobile-menu-open");
  });
  // Search open and close
  $(".search-trigger").on("click", function (e) {
    if (e.target !== this) return;
    $(".search-container").toggleClass("search-opened");

    e.preventDefault();
  });

  // $(".menu-search-toggle").on("click", function (e) {
  //   try {
  //     $(".search-container").toggleClass("search-opened");
  //   } catch (ex) {
  //     alert("An error occurred and I need to write some code to handle this!");
  //     e.preventDefault();
  //   }
  // });
  // $(".mobile-search").on("click", function (e) {
  //   e.preventDefault();
  //   try {
  //     $(".mobile-search-container").toggleClass("search-opened");
  //   } catch (ex) {
  //     alert("An error occurred and I need to write some code to handle this!");
  //   }
  // });

  $(".close-search").on("click", function (e) {
    if (e.target !== this) return;
    $(".search-container").removeClass("search-opened");
    e.preventDefault();
  });
});
